import { useState, useEffect } from 'react'
import { getProducts, getProductsByCategory } from '../../asyncMock'
import { useParams } from 'react-router-dom'
import ItemList from '../ItemList/ItemList.jsx'
import NabSubBar from '../NabSubBar/NabSubBar'
// import { db } from '../../services/firebaseConfig'
// import { collection, getDocs } from 'firebase/firestore'

const ItemListContainer = ({ welcomeText }) => {

        // This is used in case to doesnt have more queries on firebase
        const [products, setProducts] = useState([])
    
        const { categoryId } = useParams()
    
        useEffect(() => {
            const asyncFunc = categoryId ? getProductsByCategory : getProducts
    
            asyncFunc(categoryId)
                .then(
                    response => {
                        setProducts(response)
                    }).catch(error => {
                        console.log(error)
                    })
        }, [categoryId]
        )

        /*
    // Start Fire Base
    const [itemsList, setItemsList] = useState([])
    const { categoryId } = useParams()

    useEffect(() => {
        const getItems = async () => {
            const data = await getDocs(collection(db, 'items'));
            const items = data.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
            setItemsList(items);
        }

        const getItemsByCategory = async () => {
            const data = await getDocs(collection(db, 'items'));
            const items = data.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
            const filteredItems = items.filter((item) => item.category === categoryId);
            setItemsList(filteredItems);
        }

        if (categoryId) {
            getItemsByCategory();
        } else {
            getItems();
        }
    })
    // Ends Fire Base
    */

    return (
        <div>
            <NabSubBar />
            <label className="ps-3">
                <input id="searchText" type="search" className="form-control" placeholder="Busqueda por nombre" min="0"
                    required />
            </label>
            <button id="btnSearch" type="button" className="mpk_button_primary">
                Buscar
            </button>
            <button id="btnClearFilter" type="button" className="mpk_button_secondary">
                Quitar Filtro
            </button>
            <div className="row m-auto">
                <div className="col-sm text-center">
                    <ItemList products={products} />
                </div>
            </div>
        </div>
    )
}

export default ItemListContainer
