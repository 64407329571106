import React, { useContext } from 'react'
import { DataContext } from '../context/ShoppingCartContext'
import { NavLink } from 'react-router-dom'

const Cart = () => {

    const [contador, setContador] = useContext(DataContext)
    const renderCartItems = () => {
        return contador.map((item) => (
            <div key={item.id} className="card rounded cardComponent size-elements">
                <img src={item.img} alt={item.name} className="rounded" />
                <h3>{item.name}</h3>
                <p><b>Precio:</b> ${item.price}</p>
                <p><b>Cantidad:</b> {item.quantity}</p>
                <p><b>Descripcion:</b> {item.desc}</p>
                <p><b>Categoria:</b> {item.category}</p>
            </div>
        ))
    }

    // Create object with all the item properties from contador
    const itemsList = contador.map((item) => {
        return {
            id: item.id,
            name: item.name,
            quantity: item.quantity,
            desc: item.desc,
            price: item.price,
            img: item.img,
            category: item.category,
            available: item.available,
            stock: item.stock,
            initial: item.initial,
            onAdd: item.onAdd,
        }
    })

    // Get total amount according the quantity of items in the cart
    const totalAmount = itemsList.reduce((acc, item) => acc + item.price * item.quantity, 0)
    const totalQuantity = contador.reduce((acc, item) => acc + item.quantity, 0)

    const styleLink = {
        textDecoration: 'none'
    }

    const styleDiv = {
        width: '18rem'
    }

    return (
        <div>
            <h1>Carrito de Compras</h1>
            <p>Confirma tus productos antes de proceder con el pago.</p>
            <div className="row m-auto col-sm">
                {contador.length > 0 ? (
                    <div className="row mt-5 d-flex justify-content-center">
                        {renderCartItems()}
                        <p className="mt-2">Total de productos en el carrito: <b> {totalQuantity}</b></p>
                        <p>Total a Pagar: <b>${totalAmount} MXN</b></p>
                    </div>
                ) : (
                    <p>Tu carrito está vacío.</p>
                )}
            </div>
            {contador.length > 0 ? (
                <span>
                    <NavLink to="/Shop" style={styleLink} className="btn btn-secondary mb-2 me-2">Regresar</NavLink>
                    <NavLink to="/Checkout" style={styleLink} className="btn btn-primary mb-2" totalAmount={totalAmount}>Continuar</NavLink>
                </span>
            ) : (
                <NavLink to="/Shop" style={styleLink} className="btn btn-primary mb-2">Regresar</NavLink>
            )
            }
        </div>
    )
}

export default Cart
