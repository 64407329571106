import '../../static/fontawesome/all.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import logo from '../../static/images/NavBar/logo.ico'
import NavItems from '../NavItems/NavItems'
import CartWidget from '../CartWidget/CartWidget'

const NavBar = () => {
    return (
        <nav id="navMenu" className="navbar navbar-expand-lg navbar-dark fixed-top bg-dark">
            <div className="container-fluid">
                <a className="navbar-brand" href="/">
                    <img id="imgMainLogo" src={logo} alt="Logo" className="pe-3"/>
                    M I P E K I C H A
                </a>
                <button className="navbar-toggler rounded-5" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                    aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav">
                      <NavItems textDisplayed="Inicio" myUrl=""/>
                      <NavItems textDisplayed="Productos" myUrl="Productos"/>
                      <NavItems textDisplayed="Nosotros" myUrl="Nosotros"/>
                      <NavItems textDisplayed="Distribuidores" myUrl="Distribuidores"/>
                      <NavItems textDisplayed="Contacto" myUrl="Contacto"/>
                      <NavItems textDisplayed="Embajadores" myUrl="Embajadores"/>
                      <NavItems textDisplayed="Cotizar" myUrl="Shop"/>
                    </ul>
                </div>
            </div>
            <CartWidget />
        </nav>
    )
}

export default NavBar
