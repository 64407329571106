import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Home from './pages/Inicio'
import Products from './pages/Productos'
import AboutUs from './pages/Nosotros'
import Distributors from './pages/Distribuidores'
import Ambassadors from './pages/Embajadores'
import Contact from './pages/Contacto'
import Shop from './pages/Tienda'
import ItemListContainer from './components/ItemListContainer/ItemListContainer'
import Cart from './pages/Carro'
import Checkout from './pages/Checkout'
import CreateOrder from './pages/CrearOrden'
import ErrorDisplayed from './pages/ErrorDisplayed'
import './App.css'
import './scss/style.scss'
import NavBar from './components/NavBar/NavBar'
import FooterContainer from './components/FooterContainer/FooterContainer'
import ItemDetailsContainer from './components/ItemDetailsContainer/ItemDetailsContainer'
import { DataProvider } from './context/ShoppingCartContext'

const App = () => {

  return (
    <div className="App mainInfo">
      <DataProvider>
        <BrowserRouter>
          <NavBar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Productos" element={<Products />} />
            <Route path="/Nosotros" element={<AboutUs />} />
            <Route path="/Distribuidores" element={<Distributors />} />
            <Route path="/Contacto" element={<Contact />} />
            <Route path="/Embajadores" element={<Ambassadors />} />
            <Route path="/Shop" element={<Shop />} />
            <Route path="/Shop/category/:categoryId" element={<ItemListContainer />} />
            <Route path="/Shop/item/:itemId" element={<ItemDetailsContainer />} />
            <Route path="/Cart" element={<Cart />} />
            <Route path="/Checkout" element={<Checkout />} />
            <Route path="/CreateOrder" element={<CreateOrder />} />
            <Route path="*" element={<ErrorDisplayed />} />
          </Routes>
          <FooterContainer />
        </BrowserRouter>
      </DataProvider>
    </div>
  )
}

export default App
