import React, {useContext} from 'react'
import { DataContext } from '../../context/ShoppingCartContext'
import { NavLink } from 'react-router-dom'

const CartWidget = () => {

    const [contador, setContador] = useContext(DataContext)

    const quantity = contador.reduce((acc, item) => acc + item.quantity, 0)

    return (
        <div className="cart">
            <NavLink to={"./Cart"} className="fa-solid fa-cart-shopping fa-2x" id="btnCart" data-bs-toggle="modal"
                data-bs-target="#cartSection">
                <span id="numberProducts">{quantity}</span>
            </NavLink>
        </div>
    )
}

export default CartWidget
