import loading from '../static/images/App/loading.png'

const Inicio = () => {
    return (
        <main>
            <h1>Home Page</h1>
            <div className="App-header">
                <img src={loading} className="App-logo" alt="logo" />
                <p>
                    Mientras aprendemos vemos a el huesito dando vueltas.
                </p>
                <a
                    className="App-link"
                    href="https://reactjs.org"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Learn React
                </a>
            </div>
        </main>
    )
}

export default Inicio
